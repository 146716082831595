import React, { useState, useEffect } from 'react';
import './styles.css';
import { useMiningContext, asteroidTypes } from './MiningContext.js'; // Importing the context

const ProspectorMining = () => {
  const { miningInventories, setMiningInventories, MiningHeads, ships } = useMiningContext();
  const [minedOre, setMinedOre] = useState([]);
  const [foundOre, setFoundOre] = useState([]);
  const [timer, setTimer] = useState(10);
  const [scanning, setScanning] = useState(false);
  const [scanningComplete, setScanningComplete] = useState(false);
  const [mining, setMining] = useState(false);
  const [scanningPulse, setScanningPulse] = useState(false);
  const [miningPulse, setMiningPulse] = useState(false);
  const [selectedMiningHead, setSelectedMiningHead] = useState(() => {
    return localStorage.getItem('selectedMiningHead') || 'Arbor MH1';
  });
  const [notification, setNotification] = useState(null);
  const [foundAsteroidType, setFoundAsteroidType] = useState('');

  const scanningImage = '/Hurston.png'; // Image for scanning
  const miningImage = foundAsteroidType ? `${foundAsteroidType}.png` : '/Hurston.png'; // Image for mining

  const prospectorInventory = miningInventories?.Prospector || {}; // Get Prospector's inventory from MiningContext
  const inventorySize = 32;

  // Available mining heads for the Prospector
  const availableMiningHeads = {
    'Arbor MH1': 2 * 60,
    'Hofstede S1': 1 * 60 + 30,
    'Impact I': 1 * 60,
    'Helix I': 45,
  };

  const showNotification = (message, type = 'info') => {
    setNotification({ message, type });
  };

  const checkInventoryFull = () => {
    const totalCount = Object.values(prospectorInventory).reduce((acc, count) => acc + count, 0);
    return totalCount >= inventorySize;
  };

  const startScan = () => {
    if (checkInventoryFull()) {
      showNotification('Inventory is full.', 'error');
      return;
    }

    // Check if the selected mining head is owned
    const miningHeadOwned = MiningHeads[selectedMiningHead];
    if (!miningHeadOwned || miningHeadOwned <= 0) {
      showNotification('Selected mining head is not owned.');
      return;
    }

    const prospectorOwnedAmount = ships['Prospector'];

    if (!prospectorOwnedAmount || prospectorOwnedAmount <= 0) {
      showNotification('You do not own a Prospector');
      return;
    }

    setNotification(null);
    setScanningComplete(false);
    setMinedOre('');
    setScanning(true); // Disable mining during scan
    setScanningPulse(true);
    setTimer(10);

    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(interval);

      // Randomly select an asteroid type
      const asteroidTypesArray = Object.entries(asteroidTypes);
      const randomAsteroidIndex = Math.floor(Math.random() * asteroidTypesArray.length);
      const [foundAsteroidType, asteroidSpecs] = asteroidTypesArray[randomAsteroidIndex];

      const selectedOres = [];
      const availableOres = [...new Set(asteroidSpecs.map(spec => spec.ore))]; // Extract unique available ores

      setFoundAsteroidType(foundAsteroidType);

      const numSelectedOres = Math.floor(Math.random() * 2) + 2; // Randomly choose between 2 and 3 ores

      for (let i = 0; i < numSelectedOres; i++) {
        if (availableOres.length === 0) {
          break; // Break if no more available ores
        }

        const oreProbabilities = asteroidSpecs.map(spec => spec.probability);
        const totalProbability = oreProbabilities.reduce((sum, prob) => sum + prob, 0);
        const randomProbability = Math.random() * totalProbability;

        let cumulativeProbability = 0;
        let selectedOre;

        for (let j = 0; j < asteroidSpecs.length; j++) {
          cumulativeProbability += oreProbabilities[j];
          if (randomProbability <= cumulativeProbability) {
            selectedOre = asteroidSpecs[j].ore;
            break;
          }
        }

        const randomOreIndex = availableOres.indexOf(selectedOre);
        availableOres.splice(randomOreIndex, 1); // Remove selected ore from available ores
        selectedOres.push(selectedOre);
      }

      setFoundOre(selectedOres);

      setTimeout(() => {
        setTimer(10);
        setScanningPulse(false);
        setScanning(false); // Enable mining after scan
        setScanningComplete(true); // Mark scanning as complete
      }, 10000);
    });
  };

  useEffect(() => {
    let interval;
    if (scanning) {
      interval = setInterval(() => {
        if (timer > 0) {
          setTimer((prevTimer) => prevTimer - 1);
        } else {
          setScanning(false);
          setTimer(10);
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer, scanning]);

  const startMining = () => {
    if (!mining && foundOre.length > 0) {
      // Check if the selected mining head is owned
      const miningHeadOwned = MiningHeads[selectedMiningHead];
      if (!miningHeadOwned || miningHeadOwned <= 0) {
        showNotification('Selected mining head is not owned.');
        return;
      }

      setMining(true);
      setTimer(availableMiningHeads[selectedMiningHead]);
      setMiningPulse(true);
    } else {
      showNotification('Must scan for ore first', 'error');
    }
  };

  useEffect(() => {
    let interval;
    if (mining) {
      interval = setInterval(() => {
        if (timer > 0) {
          setTimer((prevTimer) => prevTimer - 1);
        } else {
          const minedQuantity = Math.round(Math.random() * (32 - 8 + 1)) + 8;
          if (minedQuantity > 0) {
            const totalMinedOre = {};

            const currentInventoryCount = Object.values(prospectorInventory).reduce((acc, count) => acc + count, 0);
            let remainingCapacity = 32 - currentInventoryCount;

            let remainingMinedQuantity = minedQuantity;

            foundOre.forEach((selectedOre) => {
              if (remainingCapacity > 0 && remainingMinedQuantity > 0) {
                const randomQuantityToAdd = Math.min(remainingCapacity, Math.floor(Math.random() * (remainingMinedQuantity - 1)) + 1);
                totalMinedOre[selectedOre] = (totalMinedOre[selectedOre] || 0) + randomQuantityToAdd;
                remainingMinedQuantity -= randomQuantityToAdd;
                remainingCapacity -= randomQuantityToAdd;
              }
            });

            // Check if there's any ore mined
            if (Object.keys(totalMinedOre).length > 0) {
              setMiningInventories((prevInventories) => ({
                ...prevInventories,
                Prospector: {
                  ...prevInventories.Prospector,
                  ...Object.entries(totalMinedOre).reduce((acc, [ore, quantity]) => {
                    const currentCount = prevInventories.Prospector[ore] || 0;
                    const newCount = currentCount + quantity;
                    acc[ore] = newCount;
                    return acc;
                  }, {}),
                },
              }));

              const minedOreArray = Object.entries(totalMinedOre).map(([ore, quantity]) => `${ore}: ${quantity}scu`);
              setMinedOre(minedOreArray);

              const leftBehindOres = foundOre.filter(ore => !(ore in totalMinedOre));
              if (leftBehindOres.length > 0) {
                showNotification(`Mining complete. Left behind: ${leftBehindOres.join(', ')}`);
              }

              setMining(false);
              setMiningPulse(false);
              setTimer(10);
              setFoundOre([]);
              setScanningComplete(false);
            } else {
              showNotification('No ore found.');
            }
          }
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer, mining, foundOre, prospectorInventory, setMiningInventories]);

  // Save the selected mining head to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('selectedMiningHead', selectedMiningHead);
  }, [selectedMiningHead]);

  return (
    <div className="container">
      <div className="image-container">
        {mining && <img src={miningImage} alt="" className={`centered ${miningPulse ? 'pulse' : ''} mining-image`} />}
        {!mining && <img src={scanningPulse ? scanningImage : miningImage} alt="" className={`centered ${scanningPulse ? 'pulse' : ''}`} />}
      </div>

      <div className="button-container">
        <button
          onClick={startScan}
          className={`button ${mining ? 'disabled' : ''}`}
          disabled={mining || scanning}
          style={{ width: '48%', marginRight: '4%' }} // Set width and margin for spacing
        >
          Scan
        </button>

        <button
          onClick={startMining}
          className={`button ${mining ? 'disabled' : ''}`}
          disabled={mining || scanning}
          style={{ width: '48%' }} // Set width
        >
          Mine
        </button>
      </div>

      <select
        value={selectedMiningHead}
        style={{ width: '35%' }}
        disabled={mining || scanning}
        onChange={(e) => {
          setSelectedMiningHead(e.target.value);
        }}
      >
        {Object.keys(availableMiningHeads).map((head) => (
          <option key={head} value={head}>
            {head}
          </option>
        ))}
      </select>

      {notification && (
        <div className={`notification ${notification.type}`}>
          {notification.message}
          <button className="close-button" onClick={() => setNotification(null)}>
            X
          </button>
        </div>
      )}

      {scanningComplete && (
        <>
          <p className="info">Asteroid: {foundAsteroidType}</p>
          <p className="info">Ore: {[...new Set(foundOre)].join(', ')}</p>
        </>
      )}

      {minedOre && (
        <div>
          <p className="info">Mined Ore:</p>
          <ul>
            {[...new Set(minedOre)].map((ore) => {
              return (
                <li key={ore} className="info" style={{ marginRight: '38px' }}>
                  {ore}
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {(mining || scanning) && <p className="info">Time: {Math.floor(timer / 60)}m {timer % 60}s</p>}

      <ul className="inventory-list">
        <h2 className="info">Cargo: {Object.values(prospectorInventory).reduce((sum, quantity) => sum + quantity, 0)}/{inventorySize}scu</h2>
        {Object.entries(prospectorInventory)
          .filter(([ore, count]) => count > 0) // Filter entries with count > 0
          .map(([ore, count]) => (
            <li key={ore} className="info">
              {ore}: {count}scu
            </li>
          ))}
      </ul>
    </div>
  );
};

export default ProspectorMining;
